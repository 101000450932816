import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren{
	title: string;
    price?: string;
    subtitle?: string;
    description?: string;
    small?: boolean;
}

export const ModalBehandelingRow = ({title, price, subtitle, description, small, children}: Props) => {

    if(small) {
        return (
            <div className="behandeling-row">
            <div className="behandeling-info-wrapper">
                <h3 className="behandeling-title">{title} </h3>
            </div>
            {children}
        </div> 
        )
    }

    return (
        <div className="behandeling-row">
            <div className="behandeling-info-wrapper">
                <h3 className="behandeling-title">{title} </h3>
                 <h3 className="behandeling-price">{price}</h3>
            </div>
            <div className="behandeling-description-wrapper">
                <div className="behandeling-subtitle">{subtitle}</div>
                <div className="behandeling-description">{description}</div>
            </div>
        </div>
    )
}