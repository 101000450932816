import styles from "./Section.module.scss";
import { Divider } from "..";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
	dividerTop?: boolean;
	dividerBottom?: boolean;
	backgroundColor?: string;
	sectionClassName?: string;
	extraPadding?: string;
	id?: string;
}

export const Section = ({
	children,
	dividerBottom,
	dividerTop,
	sectionClassName,
	backgroundColor,
	extraPadding,
	id
}: Props) => {
	return (
		<section
			id={id}
			className={`${styles.section} ${sectionClassName && sectionClassName}`}
			style={
				{
					background: backgroundColor,
					"--extra-padding": extraPadding ? extraPadding : "0px",
				} as React.CSSProperties
			}>
				<div className={styles.sectionContent}>
					{dividerTop && <Divider orientation="top" />}
					{children}
					{dividerBottom && <Divider orientation="bottom" />}

				</div>
		</section>
	);
};
