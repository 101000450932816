import { useState } from 'react';
import { Button, Contact, Nav, Section, TitleWrapper } from '../components'
import { Modal } from '../components/Modal/Modal'
import "../../src/styles/globals.scss"
import "../../src/styles/variables.scss";

const Behandelingen = () => {
    const [activeModal, setActiveModal] = useState<string>('massages');

    return (
        <>
            <Section>
                <div className="button-wrapper">
                    <Button buttonColor={activeModal === 'massages' ? 'primary' : 'secondary'} buttonText='Massages' onClick={() => setActiveModal('massages')}/>
                    <Button buttonColor={activeModal === 'gezichtsbehandelingen' ? 'primary' : 'secondary'} buttonText='Gezichtsbehandelingen' onClick={() => setActiveModal('gezichtsbehandelingen')}/>
                </div>
                <Nav behandelingPage/>
                <Modal activeModal={activeModal} />
            </Section>
            <Section backgroundColor="var(--color-dark-green)" id="contact">
                <TitleWrapper textAlign="center" title="Waar kun je mij vinden?" subtitle="Contact" />
                <Contact/>
            </Section>
            <footer>
                <div className="hannet"><p>Handmade by Hannet ©</p></div>
            </footer>
        </>
)
}

export default Behandelingen