import styles from "./Brand.module.scss";
import { ImageContainer } from "..";

interface Props {
	image: string;
	title: string;
	description?: string;
	onClick?: () => void;
}

export const Brand = ({ image, title, description}: Props) => {
	return (
		<div className={styles.brand}>
			<ImageContainer className={styles.brandImage}>
				<img src={image} alt={`behandeling ${title}`}/>
			</ImageContainer>
			<div>
				<h3>{title}</h3>
				<p>{description}</p>
			</div>
		</div>
	);
};
