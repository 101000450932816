import { ModalBehandelingRow } from '../ModalBehandelingRow'

export const MassageModal = () => {
    return (
        <div className="behandelingen-wrapper">
            <ModalBehandelingRow 
                subtitle="45 minuten:" 
                price='€ 50,00' 
                title='Klassieke Lichaamsmassage'
                description="Achterzijde benen, rug, nek, schouders en hoofd"
            />
            <ModalBehandelingRow 
                subtitle="60 minuten" 
                price='€ 60,00' 
                title='Klassieke Lichaamsmassage'
                description='Achterzijde en voorzijde benen, armen, rug, nek, schouders en hoofd'
            />
            <ModalBehandelingRow 
                subtitle="30 minuten" 
                price='€ 35,00' 
                title='Rug, nek en schoudermassage'
            />
            <ModalBehandelingRow 
                subtitle="60 minuten"
                price='€ 60,00' 
                title='Hotstone massage'
                description='Achterzijde benen, rug en armen'
            />
            <ModalBehandelingRow 
                subtitle="90 minuten" 
                price='€ 80,00' 
                title='Hotstone massage'
                description='Hele lichaam voor en achterkant.'
            />
            <ModalBehandelingRow 
                subtitle="20 minuten" 
                price='€ 25,00' 
                title='Gezichtsmassage'
                description='Gezicht, nek en schouders'
            />
            <ModalBehandelingRow
                subtitle='55 minuten'
                price='€ 50,00' 
                title='Spa & Wellness'
                description='Scrubben van de rug, voedende rug pakking, massage van de achterkant benen, rug, nek en schouder massage en hydraterende body butter.'
            />
            <ModalBehandelingRow
                subtitle='45 minuten'
                price='€ 50,00' 
                title='Lomi lomi'
                description=''
            />
            <ModalBehandelingRow
                subtitle='60 minuten'
                price='€ 60,00' 
                title='Lomi lomi'
                description=''
            />
        </div>
    )
}