import styles from "./Header.module.scss";
import {Divider, Nav, ImageContainer } from "../../components";

export const Header = () => {
	return (
		<div className={styles.header}>
			<ImageContainer className={styles.heroImage}>
				<img alt="massage" src="/hero.png" className='hero-image'/>
			</ImageContainer>
			<Nav />	
			<Divider orientation="bottom" />
		</div>
	);
};
