import { ModalBehandelingRow } from '../ModalBehandelingRow'

export const SchoonheidsModal = () => {
    return (
        <div className="behandelingen-wrapper">
            <ModalBehandelingRow 
                subtitle="45 minuten" 
                price='€ 35,00' 
                title='Reinigend'
                description="Huidanalyse, reiniging, peeling, onzuiverheden verwijderen, masker, serum en dag/nacht verzorging"
            />
            <ModalBehandelingRow 
                subtitle="45 minuten"
                price='€ 35,00' 
                title='Ontspannend'
                description='Huidanalyse, reiniging, peeling, massage, masker, serum en dag/nacht verzorging'
            />
            <ModalBehandelingRow 
                subtitle="75 a 90 minuten"
                price='€ 60,00' 
                title='Gericht voor jou huid'
                description='Huidanalyse, reiniging, peeling, wenkbrauwen epileren, onzuiverheden verwijderen, concentraat, masker, decolleté, nek, arm en hand massage, serum, oog crème en dag/nacht verzorging'
            />
            <ModalBehandelingRow 
                title='Wenkbrauwen en wimpers'
                small
            >
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Epileren / Harsen wenkbrauwen</div>
                    <h3 className="behandeling-price">€ 17,00</h3>
                </div>
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Verven wimpers</div>
                    <h3 className="behandeling-price">€ 15,00</h3>
                </div>
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Verven wenkbrauwen</div>
                    <h3 className="behandeling-price">€ 15,00</h3>
                </div>
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Epileren & wenkbrauwen verven</div>
                    <h3 className="behandeling-price">€ 23,50</h3>
                </div>
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Epileren, wenkbrauwen en wimpers verven</div>
                    <h3 className="behandeling-price">€ 25,50</h3>
                </div>
            </ModalBehandelingRow>
            <ModalBehandelingRow 
                title='Ontharen'
                small
            >
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Harsen bovenlip</div>
                    <h3 className="behandeling-price">€ 15,00</h3>
                </div>
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Harsen kin</div>
                    <h3 className="behandeling-price">€ 15,00</h3>
                </div>
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Harsen bovenlip en kin</div>
                    <h3 className="behandeling-price">€ 23,50</h3>
                </div>
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Hele gelaat harsen</div>
                    <h3 className="behandeling-price">€ 30,00</h3>
                </div>
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Harsen onderbenen of bovenbenen</div>
                    <h3 className="behandeling-price">€ 25,00</h3>
                </div>
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Harsen gehele benen</div>
                    <h3 className="behandeling-price">€ 30,00</h3>
                </div>
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Harsen rug of armen</div>
                    <h3 className="behandeling-price">€ 20,00</h3>
                </div>
                <div className="behandeling-description-wrapper small">
                    <div className="behandeling-description">Harsen oksels</div>
                    <h3 className="behandeling-price">€ 17,50</h3>
                </div>
            </ModalBehandelingRow>
        </div>
    )
}