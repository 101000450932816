import { useState } from "react";
import {
	Header,
	Section,
	TitleWrapper,
	ContentBlock,
	InBetween,
	Button,
  Contact,
  BrandsLibrary
} from "../../src/components";
import "../../src/styles/globals.scss"
import "../../src/styles/variables.scss";
import { CadeauBlock } from '../components/Cadeau/CadeauBlock';
import { HashLink, NavHashLink } from 'react-router-hash-link';

function Home() {
  const [inbetweenHeight, setInbetweenHeight] = useState<string>();
  console.log(inbetweenHeight)

  return (
    <>
    <Header />
    <Section id="behandelingen">
      <TitleWrapper textAlign="center" title="Behandelingen en tarieven" subtitle="Wat kan ik voor je doen?" />
      <ContentBlock imageUrl="/schoonheid.jpg" textContentAlign='left' textContentWidth={60} shadow>
        <h3>Gezichtsbehandelingen</h3>
        <p>In mijn salon wordt de focus gelegd op het verbeteren van uw huidconditie. Bij een gezichtsbehandeling wordt er gekeken naar uw specifieke huidtype waar vervolgens de gezichtsbehandeling en producten op aangepast worden.</p>
        <Button buttonColor="primary" buttonText="Tarieven & behandelingen" buttonUrl='/#/behandelingen'/>
      </ContentBlock>
      <ContentBlock imageUrl="/img-massage.jpg" textContentAlign='right' textContentWidth={60} shadow>
        <h3>Massages</h3>
        <p>Een ontspanningsmassage richt zich op het losmaken van de spieren en zorgt ervoor dat de doorbloeding weer op gang komt. Verder draagt een massage bij aan ontspanning, minder stress, soepelere spieren en een betere slaap.</p>
        <Button buttonColor="primary" buttonText="Tarieven & behandelingen" buttonUrl='/#/behandelingen'/>
      </ContentBlock>
      <ContentBlock imageUrl="img-schoonheid.jpg" textContentAlign='left' textContentWidth={60} shadow>
        <h3>Visagie</h3>
        <p>Naast schoonheidssbehandelingen is visagie ook een passie van mij, dit doe ik graag na de behandeling of op locatie, voor bijvoorbeeld een bruidsmakeup of voor daggasten. Dit doe ik op aanvraag! Neem voor vragen en de mogelijkheden contact met mij op.</p>
        <NavHashLink to='/#contact' className="button margin-top">Contact</NavHashLink>
      </ContentBlock>
    </Section>
    <InBetween setInbetweenHeight={setInbetweenHeight} imageUrl="/over-mij.png" id="overmij">
      <TitleWrapper title="Over mij" subtitle='Wie ben ik?' textAlign="left" />
      <p>
      Mijn naam is Tessa Edelenbos en met trots heb ik in 2019 mijn eigen salon geopend: Relax Salon Tessa. In 2018 heb ik mijn diploma schoonheidsspecialsite behaald. Ook heb ik een enorme passie voor masseren. Hierin heb ik verschillende specialisaties gedaan en certificaten behaald, waaronder Hotstone massage, Tuina massage en Anatomie & Fysiologie. Door middel van cursussen en beurzen blijf ik mijn kennis en behandelingen uitbreiden. Tot slot heb ik een cursus visagie gedaan en vind ik het leuk om make-up aan te brengen en hier over te adviseren. Mijn doel tijdens de behandelingen is te zorgen dat u weer even helemaal tot rust komt, optimaal kunt ontspannen en dat u de deur uitstapt met een heerlijk verzorgde huid.
      </p>
    </InBetween>
    <Section id='producten'>
        <BrandsLibrary />
    </Section>
    <Section id='cadeaubon' dividerTop={true} backgroundColor="#fff2ea" >
      <CadeauBlock textContentAlign='center' textContentWidth={60}>
        <TitleWrapper textAlign="center" title="Cadeautje geven?" subtitle="Massage cadeau" />
        <p>
          Een massage is natuurlijk een heerlijk moment voor jezelf en dat maakt het een super leuk cadeau
          om te geven of te krijgen! Er zijn cadeaubonnen voor elke massagebehandeling en voor elke duur. Neem contact op voor meer informatie.
        </p>
      </CadeauBlock>
    </Section>
    <Section backgroundColor="var(--color-dark-green)" id="contact">
        <TitleWrapper textAlign="center" title="Waar kun je mij vinden?" subtitle="Contact" />
        <Contact/>
    </Section>
    <footer>
        <div className="hannet"><p>Handmade by Hannet ©</p></div>
    </footer>
  </>
  );
}

export default Home;
