import { Button } from '../Button/Button';
import { SchoonheidsModal } from './Modals/SchoonheidsModal';
import { MassageModal } from './Modals/MassageModal';

interface Props {
    activeModal?: string;
}

export const Modal = ({activeModal}: Props) => {

    return (
        <div className={`modal ${!!activeModal && 'is-active'}`}>
            <div className="modal-content-wrapper">
                <div className="modal-title-wrapper">
                    <div className="modal-icon">
                        <img src='https://img.icons8.com/wired/64/000000/euro-pound-exchange.png' alt='modal icon'/>
                    </div>
                    <h2 className="modal-title">{`Tarieven ${activeModal}`}</h2>
                </div>
                {activeModal === 'gezichtsbehandelingen' 
                ? <SchoonheidsModal/> 
                : activeModal === 'massages' 
                    ? <MassageModal/> 
                    : null }
                <p className='small margin-top-small'>* Alle geboekte behandelingen zijn gratis te annuleren tot 24 uur vantevoren. Bij annulering binnen 24 uur van de afspraak, wordt 50% van het bedrag in rekening gebracht.</p>            
            </div>
        </div>
        )
}