import { Route, Routes } from 'react-router-dom';
import "../src/styles/globals.scss"
import "../src/styles/variables.scss";
import Home from './pages/Home';
import Behandelingen from './pages/Behandelingen';


function App() {

  return (
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/behandelingen" element={<Behandelingen/>} />
      </Routes>
  );
}

export default App;
