import React from "react";
import styles from "./CadeauBlock.module.scss";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
	textContentAlign: "left" | "right" | "center";
	textContentWidth: number;
	imageUrl?: string;
	shadow?: boolean;
}

export const CadeauBlock = ({ textContentAlign, textContentWidth, children }: Props) => {
	return (
		<div className={`${styles.contentWrapper} ${styles[textContentAlign]}`}>
			<div className={styles.textContentWrapper}>
				<div
					className={styles.textContent}
					style={{ "--element-width": textContentWidth + "%" } as React.CSSProperties}>
					{children}
				</div>
			</div>
		</div>
	);
};
