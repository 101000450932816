import { FunctionComponent, useState } from 'react';
import { NavHashLink } from 'react-router-hash-link';


interface Props {
    behandelingPage?: boolean;
}

export const Nav: FunctionComponent<Props> = ({behandelingPage}) => {

	const [hamburgerActive, setHamburgerActive] = useState(false)

	const toggleHamburgerMenu = () => {
		setHamburgerActive(!hamburgerActive)
	};

	return (
		<header>
        <div id="menu">
            <nav className="topnav">
                <a id="home" href='/'/>
                <ul className="navlinks">
                    <NavHashLink to="/#behandelingen">Behandelingen</NavHashLink>
                    <NavHashLink to="/#overmij">Wie ben ik?</NavHashLink>
                    <NavHashLink to="/#producten">Producten</NavHashLink>
                    <NavHashLink to="/#cadeaubon">Cadeau</NavHashLink>
                    <NavHashLink to={behandelingPage ? '/behandelingen#contact' : '/#contact'} className="button">Contact</NavHashLink>  
                </ul>
            </nav>
        </div>
        <div id="mobilemenu" className={hamburgerActive ? 'hamburger-menu is-active' : 'hamburger-menu'} role="navigation" >
            <div id="menuToggle">
              <div className='mobilemenu-icon'onClick={toggleHamburgerMenu} />
              <span></span>
              <span></span>
              <span></span>
              
              <ul className='mobilemenu-items'>
                <NavHashLink onClick={toggleHamburgerMenu} to="/#behandelingen">Behandelingen</NavHashLink>
                <NavHashLink onClick={toggleHamburgerMenu} to="/#overmij">Wie ben ik?</NavHashLink>
                <NavHashLink onClick={toggleHamburgerMenu} to="/#producten">Producten</NavHashLink>
                <NavHashLink onClick={toggleHamburgerMenu} to="/#cadeaubon">Cadeau</NavHashLink>
                <NavHashLink onClick={toggleHamburgerMenu} to={behandelingPage ? '/behandelingen#contact' : '/#contact'} >Contact</NavHashLink>  
              </ul>
            </div>
          </div>
    </header>
	);
};
