import styles from "./BrandsLibrary.module.scss";
import { TitleWrapper } from "..";
import { Brand } from '../Brand/Brand';


export const BrandsLibrary = () => {
	const cards = [
		{
			image: "/farmona.png",
			title: "Farmona",
			modalName: "farmona",
			description: "Farmona combineert nieuwe ontwikkelingen in de cosmetica met de kracht van natuurlijke en plantaardige ingrediënten. De producten zijn ontwikkeld met zeer hoogwaardige en kwalitatieve ingrediënten voor een betaalbare prijs. Verder is Farmona dierproefvrij en zijn de producten veilig voor het milieu."
		},
		{
			image: "/hean.png",
			title: "Hean",
			modalName: "hean",
			description: "HEAN is een continu vernieuwend en hip merk met hoge kwaliteit en een uitgebreid assortiment. De cosmeticaproducten van HEAN zijn niet op dieren getest en het gebruik van allergenen is beperkt. Daarnaast volgt Hean cosmetisch de make-up trends op de voet en creëren ze prachtige producten met hoge pigmentatie en hoogwaardige formules. Dit maakt het een fijn merk om mee te werken."
		},
		{
			image: "ceecee.png",
			title: "Cee Cee",
			modalName: "ceecee",
			description: "Cee Cee is een verzorgende lijn met gezichtsverzorgingsproducten voor thuis. De producten zijn op basis van hoogwaardige, natuurlijke en plantaardige werkstoffen. De crèmes zijn geschikt voor ieder huidtype en gaan perfect samen met de liftende en hydraterende serums van Farmona.De luxe en moderne verpakkingen en geschenksets zijn verder perfect om cadeau te geven."
		},
	];

	return (
		<>
			<TitleWrapper
				textAlign="center"
				title="Welke producten gebruik ik?"
				subtitle="Producten"
			/>
			<div className={styles.brandLibrary}>
				{cards.map((card) => (
					<Brand title={card.title} image={card.image} key={card.image} description={card.description}/>
				))}
			</div>
		</>
	);
};
