export const Contact = () => {

	return (
        <section className="contact-section">
            <div className="contact-wrapper">
                <a className="contact-item" href="mailto:info@relaxsalontessa.nl">
                    <div className="contact-icon">
                        <img src="https://img.icons8.com/wired/128/000000/mailbox-closed-flag-down.png"/>
                    </div>
                    <h4 className="contact-title">Mail</h4>
                </a>
                <a className="contact-item" href="https://maps.app.goo.gl/AW4EsJSAvsLrEHCKA" target="_blank" rel="noreferrer">
                    <div className="contact-icon">
                        <img src="https://img.icons8.com/wired/128/000000/home.png"/>
                    </div>
                    <h4 className="contact-title">Salon</h4>
                </a>
                <a className="contact-item" href="https://www.facebook.com/relaxsalontessa/" target="_blank" rel="noreferrer">
                    <div className="contact-icon">
                        <img src="https://img.icons8.com/wired/128/000000/facebook--v1.png"/>
                    </div>
                    <h4 className="contact-title">Facebook</h4>
                </a>
                <a className="contact-item" href="https://www.instagram.com/relaxsalontessaa/" target="_blank" rel="noreferrer">
                    <div className="contact-icon">
                        <img src="https://img.icons8.com/wired/128/000000/apple-phone.png"/>
                    </div>
                    <h4 className="contact-title">Instagram</h4>
                </a>
            </div>

        </section>
        
	);
};